import React from "react"

import Layout from "../components/layout"
import TextBlock from "../components/text-block"
import Seo from "../components/seo"

const DataprotectionPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Data Protection" />
    <TextBlock bg="violet" left>
      <h1>Datenschutzerklärung</h1>

      <h2>Grundlegendes</h2>

      <p>
        Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art,
        den Umfang und den Zweck der Erhebung und Verwendung personenbezogener
        Daten durch den Websitebetreiber patrickkrueger.com, Marienstraße 7,
        10117 Berlin-Mitte informieren.
      </p>

      <p>
        Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt
        Ihre personenbezogenen Daten vertraulich und entsprechend der
        gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
        Weiterentwicklung dieser Webseite Änderungen an dieser
        Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich
        die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
      </p>

      <p>
        Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten”
        oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
      </p>

      <h2>Zugriffsdaten</h2>

      <p>
        Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres
        berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über
        Zugriffe auf die Website und speichern diese als „Server-Logfiles“ auf
        dem Server der Website ab. Folgende Daten werden so protokolliert:
      </p>

      <ul>
        <li>Besuchte Website</li>
        <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
        <li>Menge der gesendeten Daten in Byte</li>
        <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
        <li>Verwendeter Browser</li>
        <li>Verwendetes Betriebssystem</li>
        <li>Verwendete IP-Adresse</li>
      </ul>

      <p>
        Die Server-Logfiles werden für maximal 30 Tage gespeichert und
        anschließend gelöscht. Die Speicherung der Daten erfolgt aus
        Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können.
        Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von
        der Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
      </p>

      <h2>Reichweitenmessung & Cookies</h2>

      <p>
        Diese Website verwendet Cookies zur pseudonymisierten
        Reichweitenmessung, die entweder von unserem Server oder dem Server
        Dritter an den Browser des Nutzers übertragen werden. Bei Cookies
        handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert
        werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz von
        Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser
        Website.
      </p>

      <p>
        Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem
        Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien hier
        widersprechen:
      </p>

      <ul>
        <li>
          Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
          <br />
          <a href="http://optout.networkadvertising.org/">
            http://optout.networkadvertising.org/
          </a>
        </li>
        <li>
          Cookie-Deaktivierungsseite der US-amerikanischen Website:
          <br />
          <a href="http://optout.aboutads.info/">
            http://optout.aboutads.info/
          </a>
        </li>
        <li>
          Cookie-Deaktivierungsseite der europäischen Website:
          <br />
          <a href="http://optout.networkadvertising.org/">
            http://optout.networkadvertising.org/
          </a>
        </li>
      </ul>

      <p>
        Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen.
        Hinweis: Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser
        Website ohne Einschränkungen zugreifen können, wenn Sie entsprechende
        Einstellungen vornehmen.
      </p>

      <h3>Erfassung und Verarbeitung personenbezogener Daten</h3>

      <p>
        Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten
        nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie
        in die Datenerhebung einwilligen.
      </p>

      <p>
        Als personenbezogene Daten gelten sämtliche Informationen, welche dazu
        dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt
        werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und
        Telefonnummer.
      </p>

      <p>
        Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu
        machen. Zur Verbesserung unseres Online-Angebotes speichern wir jedoch
        (ohne Personenbezug) Ihre Zugriffsdaten auf diese Website. Zu diesen
        Zugriffsdaten gehören z. B. die von Ihnen angeforderte Datei oder der
        Name Ihres Internet-Providers. Durch die Anonymisierung der Daten sind
        Rückschlüsse auf Ihre Person nicht möglich.
      </p>

      <h2>Umgang mit Kontaktdaten</h2>

      <p>
        Nehmen Sie mit uns als Websitebetreiber durch die angebotenen
        Kontaktmöglichkeiten Verbindung auf, werden Ihre Angaben gespeichert,
        damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage
        zurückgegriffen werden kann. Ohne Ihre Einwilligung werden diese Daten
        nicht an Dritte weitergegeben.
      </p>

      <h2>Google Analytics</h2>

      <p>
        Diese Website nutzt aufgrund unserer berechtigten Interessen zur
        Optimierung und Analyse unseres Online-Angebots im Sinne des Art. 6 Abs.
        1 lit. f. DSGVO den Dienst „Google Analytics“, welcher von der Google
        Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) angeboten
        wird. Der Dienst (Google Analytics) verwendet „Cookies“ – Textdateien,
        welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies
        gesammelten Informationen werden im Regelfall an einen Google-Server in
        den USA gesandt und dort gespeichert.
      </p>

      <p>
        Google LLC hält das europäische Datenschutzrecht ein und ist unter dem
        Privacy-Shield-Abkommen zertifiziert:
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>
      </p>

      <p>
        Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der
        Nutzer wird innerhalb der Mitgliedsstaaten der EU und des Europäischen
        Wirtschaftsraum und in den anderen Vertragsstaaten des Abkommens
        gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst ungekürzt in
        die USA an einen Server von Google übertragen und dort gekürzt. Durch
        diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom
        Browser übermittelte IP-Adresse des Nutzers wird nicht mit anderen von
        Google gespeicherten Daten kombiniert.
      </p>

      <p>
        Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als
        Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese
        mithilfe der gesammelten Informationen eine Auswertung der
        Websitenutzung und der Websiteaktivität und erbringt mit der
        Internetnutzung verbundene Dienstleistungen.
      </p>

      <p>
        Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die
        Nutzung unseres Online-Angebots durch die einzelnen Nutzer auswerten zu
        können, z. B. um Reports über die Aktivität auf der Website zu
        erstellen, um unser Online-Angebot zu verbessern.
      </p>

      <p>
        Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät
        zu verhindern, indem Sie in Ihrem Browser entsprechende Einstellungen
        vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen
        dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser
        keine Cookies zulässt.
      </p>

      <p>
        Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch
        Cookies gesammelten Informationen (inklusive Ihrer IP-Adresse) an die
        Google Inc. gesendet und von der Google Inc. genutzt werden. Folgender
        Link führt Sie zu dem entsprechenden Plugin:
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
      </p>

      <p>
        Hier finden Sie weitere Informationen zur Datennutzung durch die Google
        Inc.:
      </p>

      <ul>
        <li>
          Daten, die von Google-Partnern erhoben werden
          <br />
          <a href="https://policies.google.com/privacy/partners?hl=de">
            https://policies.google.com/privacy/partners?hl=de
          </a>
        </li>
        <li>
          Einstellungen über Werbung, die Ihnen angezeigt wird
          <br />
          <a href="https://adssettings.google.de/authenticated">
            https://adssettings.google.de/authenticated
          </a>
        </li>
        <li>
          Verwendung von Cookies in Anzeigen
          <br />
          <a href="https://policies.google.com/technologies/ads?hl=de">
            https://policies.google.com/technologies/ads?hl=de
          </a>
        </li>
      </ul>

      <h2>Rechte des Nutzers</h2>

      <p>
        Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft
        darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert
        wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und
        auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen
        Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität
        geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig
        verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einreichen.
      </p>

      <h2>Löschung von Daten</h2>

      <p>
        Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung
        von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein
        Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden,
        sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es
        keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine
        Löschung nicht durchgeführt werden kann, da die Daten für zulässige
        gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der
        Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht
        für andere Zwecke verarbeitet.
      </p>

      <h2>Widerspruchsrecht</h2>

      <p>
        Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch
        machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit
        widersprechen.
      </p>

      <p>
        Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu
        Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen
        bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen
        Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie
        sich bitte an folgende E-Mail-Adresse:{" "}
        <a href="mailto:pk@patrickkrueger.com">pk@patrickkrueger.com</a>
      </p>
    </TextBlock>
  </Layout>
)

export default DataprotectionPage
